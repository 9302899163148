<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="titmsg">填写并核对订单信息</div>
        <el-col :span="24">
          <div class="titBox">
            <TitleHeader msg="收货信息"></TitleHeader>
          </div>
          <DeliveryInfo v-if="isReady" :goodsList="orderList"></DeliveryInfo>
        </el-col>
       
        <!-- <el-col :span="24">
           <div class="titBox">
          <TitleHeader msg="支付方式"></TitleHeader>
           </div>
          <div class="paymentType">
            <div class="paymentLine">
              <span class="TitLabel">支付方式:</span>
              <span class="payBox">
                在线支付
              </span>
            </div>
          </div>
        </el-col> -->
        <el-col :span="24">
            <div class="notitBox">
          <TitleHeader msg="商品清单" :noBorder="true"></TitleHeader>
            </div>
          <CommodityList
            v-if="isReady"
            :goodsList="orderList"
            @OrderFun="amountOrderFun"
            @ItemFun="amountItemFun"
          ></CommodityList>
        </el-col>
        <el-col :span="24">
           <div class="titBox">
          <TitleHeader msg="发票信息"></TitleHeader>
           </div>
          <div class="invoiceItem">
            <p>
              <span class="TitLabel">开票方式:</span>
              <span>{{ invoiceType }}</span>
            </p>
            <p>
              <span class="TitLabel">发票类型:</span>
              <span>{{ invoiceManner }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span="24" class="CouponListBigBox"   v-if="couponList.length>0 || specCouponList.length>0 || noBuyCouponList.length>0 ">
           <div class="titBox">
          <TitleHeader msg="使用优惠"></TitleHeader>
           </div>
          <div class="CouponListBox">
              <div class="CouponBox" v-if="couponList.length>0">
                <!-- couponList -->
                <p class="TitP">通用券</p>
                <ul>
                 
                  <li  v-for="couponItem in couponList" :key="couponItem.id" @click="CheckedFun(couponItem)">
                    <!-- nomal  正常     active 选中    disable不可选-->
                    <!-- {{ couponItem.isChecked?'已选中':'未选中' }}  {{ couponItem.superimposeStatus==0?'不可叠加':'可叠加' }} -->
               <!-- <p>是否不可选 {{ couponItem.AllStatus}}  </p> -->
                    <div :class="couponItem.AllStatus==0?'couponItemBox disable': couponItem.isChecked?'couponItemBox active':'couponItemBox nomal'">
                      <div class="checkedBox" v-if="couponItem.isChecked"></div>
                      <div class="Hearbox"></div>
                      <div class="mainBox">
                        <div class="mainTop">
                            <div class="numWraper">
                              <span class="num" v-if="couponItem.promotionMethod==0">￥{{ couponItem.promotionValue }}</span>
                              <span class="num" v-if="couponItem.promotionMethod==1">{{ couponItem.promotionValue }}折</span>
                            </div>
                            <!-- 无门槛 --><div class="numMsg"  v-if="couponItem.useCondition==0">全场商品可用</div>
                            <!-- 满减 --><div class="numMsg"  v-if="couponItem.useCondition==1">满{{ couponItem.useAmountCondition }}元可用</div>
                            <!-- 数量 --><div class="numMsg"  v-if="couponItem.useCondition==2">
                                              <span v-if="couponItem.couponType==4">满{{ couponItem.useNumCondition }}个未购商品可用</span>
                                              <span v-else>满{{ couponItem.useNumCondition }}个品种可用</span>   
                                        </div>
                        </div>
                        <div class="clear"></div>
                        <div class="date" v-if=" couponItem.validEndTimeFlag==-1 ">使用时间：{{couponItem.validStartTime.substring(0,10).replace(/-/g,'.')}} - {{couponItem.validEndTime.substring(0,10).replace(/-/g,'.')}}</div>
                        <div class="date" v-else>使用时间：{{couponItem.validStartTime.substring(0,10)}}-{{couponItem.failureTime.substring(0,10)}}</div>
                      </div>
                      <!-- [{{Number(couponItem.couponType)==0?'全场通用券':Number(couponItem.couponType)==1?'单品券':Number(couponItem.couponType)==2?'多品券':Number(couponItem.couponType)==4?'未购券':'品类券'}} ] -->
                      <p>[{{ couponItem.couponName }}]</p>
                    </div>
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
              <div class="CouponBox"  v-if="specCouponList.length>0">
                <!-- specCouponList -->
                <p class="TitP">多品/品类券</p>
                <ul >
                  <li v-for="couponItem in specCouponList"  :key="couponItem.id"  @click="CheckedFun(couponItem)">
                    <!-- nomal  正常     active 选中   disable不可选           superimposeStatus 0:不可叠加  1:可叠加-->
                    <!-- {{ couponItem.isChecked?'已选中':'未选中' }}  {{ couponItem.superimposeStatus==0?'不可叠加':'可叠加' }} -->
                    <div :class="couponItem.AllStatus==0?'couponItemBox disable': couponItem.isChecked?'couponItemBox active':'couponItemBox nomal'">
                      <div class="checkedBox"  v-if="couponItem.isChecked"></div>
                      <div class="Hearbox"></div>
                      <div class="mainBox">
                        <div class="mainTop">
                            <div class="numWraper">
                              <span class="num" v-if="couponItem.promotionMethod==0">￥{{ couponItem.promotionValue }}</span>
                              <span class="num" v-if="couponItem.promotionMethod==1">{{ couponItem.promotionValue }}折</span>
                            </div>
                            <!-- 无门槛 --><div class="numMsg"  v-if="couponItem.useCondition==0">全场商品可用</div>
                            <!-- 满减 --><div class="numMsg"  v-if="couponItem.useCondition==1">满{{ couponItem.useAmountCondition }}元可用</div>
                            <!-- 数量 --><div class="numMsg"  v-if="couponItem.useCondition==2">
                                              <span v-if="couponItem.couponType==4">满{{ couponItem.useNumCondition }}个未购商品可用</span>
                                              <span v-else>满{{ couponItem.useNumCondition }}个品种可用</span>   
                                        </div>
                        </div>
                        <div class="clear"></div>
                        <div class="date" v-if=" couponItem.validEndTimeFlag==-1 ">使用时间：{{couponItem.validStartTime.substring(0,10).replace(/-/g,'.')}} - {{couponItem.validEndTime.substring(0,10).replace(/-/g,'.')}}</div>
                        <div class="date" v-else>使用时间：{{couponItem.validStartTime.substring(0,10)}}-{{couponItem.failureTime.substring(0,10)}}</div>
                      </div>
                      <p> [{{ couponItem.couponName }}]  【{{ couponItem.superimposeStatus==0?'不可叠加':'可叠加' }}】</p>
                    </div>
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
              <div class="CouponBox"  v-if="noBuyCouponList.length>0">
                <!-- noBuyCouponList -->
                <p class="TitP">未购券</p>
                <ul>
                  <li  v-for="couponItem in noBuyCouponList"  :key="couponItem.id"  @click="CheckedFun(couponItem)">
                    <!-- nomal  正常     active 选中    disable不可选-->
                    <!-- {{ couponItem.isChecked }}   {{ couponItem.superimposeStatus }} -->
                    <div :class="couponItem.isChecked?'couponItemBox active':'couponItemBox nomal'">
                      <div class="checkedBox"  v-if="couponItem.isChecked"></div>
                      <div class="Hearbox"></div>
                      <div class="mainBox">
                        <div class="mainTop">
                            <div class="numWraper">
                              <span class="num" v-if="couponItem.promotionMethod==0">￥{{ couponItem.promotionValue }}</span>
                              <span class="num" v-if="couponItem.promotionMethod==1">{{ couponItem.promotionValue }}折</span>
                            </div>
                            <!-- 无门槛 --><div class="numMsg"  v-if="couponItem.useCondition==0">全场商品可用</div>
                            <!-- 满减 --><div class="numMsg"  v-if="couponItem.useCondition==1">满{{ couponItem.useAmountCondition }}元可用</div>
                            <!-- 数量 --><div class="numMsg"  v-if="couponItem.useCondition==2">
                                              <span v-if="couponItem.couponType==4">满{{ couponItem.useNumCondition }}个未购商品可用</span>
                                              <span v-else>满{{ couponItem.useNumCondition }}个品种可用</span>   
                                        </div>
                        </div>
                        <div class="clear"></div>
                        <div class="date" v-if=" couponItem.validEndTimeFlag==-1 ">使用时间：{{couponItem.validStartTime.substring(0,10).replace(/-/g,'.')}} - {{couponItem.validEndTime.substring(0,10).replace(/-/g,'.')}}</div>
                        <div class="date" v-else>使用时间：{{couponItem.validStartTime.substring(0,10)}}-{{couponItem.failureTime.substring(0,10)}}</div>
                      </div>
                      <p> [{{ couponItem.couponName }}]</p>
                    </div>
                  </li>
                </ul>
                <div class="clear"></div>
              </div>
          </div>
        </el-col>
        <el-col :span="24" class="complimentary">
          <el-col :span="12" class="remarkBox">
            <div class="leftRemark">
              备注：
            </div>
            <div class="rightRemark">
              <el-input
                type="textarea"
                v-model="orderList.note"
                placeholder="请输入您要留言的内容"
                resize="none"
              ></el-input>
            </div>
          </el-col>
          <!-- <el-col :span="12" class="specialOfferBox">
            <div class="topSpecialOffer">
                <span class="desTit">品类/多品优惠券:</span>
                <div class="desCenter">
                    已为您选择最优
                </div>
                <span class="desEnd">-￥{{moreAmount}}</span>
            </div>
            <div class="bottomSpecialOffer">
                <span class="desTit">订单优惠券:</span>
                <div class="desCenter">
                  已为您选择最优
                    //<el-select  v-model="ordereCouponSelected" clearable placeholder="请选择"  @change="ordereCouponFun">
                    //      <el-option
                    //        v-for="item in goodsList.couponList"
                    //        :key="item.id"
                    //        :label="item.couponName"
                    //        :value="{ couponId: item.couponId, label: item.name ,amount:item.amount}"
                    //      >
                    //      </el-option>
                    //  </el-select>
                </div>
                <span class="desEnd">-￥{{orderAmount}}</span>
            </div>
          </el-col> -->
      </el-col>


      </el-col>
    </el-row>
    <DiscountFree v-if="isReady" :goodsList="orderList"></DiscountFree>
    <AmountBottom v-if="isReady" :goodsList="orderList"></AmountBottom>
  </div>
</template>

<script>
const TitleHeader = () => import("components/common/TitleHeader.vue");
const DeliveryInfo = () => import("components/order/DeliveryInfo.vue");
const CommodityList = () => import("components/order/CommodityList.vue");
const DiscountFree = () => import("components/order/DiscountFree.vue");
const AmountBottom = () => import("components/order/AmountBottom.vue");
// import { CartList } from "api/product.js"  , relativeCoupon;
import { carToOrder } from "api/carToOrder.js";
// import {AgainOrder} from "api/order.js";
// import {mapState} from "vuex";
import { PromotionOrder } from "api/activity.js";
import {freightList} from "api/order.js";
export default {
  name: "GoodsAmount",
  data() {
    return {
      isReady:false,
      moreIds: "", //购物车商品ids
      orderList: "",
    
      invoiceManner: "", //发票类型
      invoiceType: "", //开票方式
      PayOptions: [
        {
          value: "15",
          label: "顺丰快递15元",
        },
        {
          value: "10",
          label: "京东物流10元",
        },
        {
          value: "5",
          label: "菜鸟快递5元",
        },
      ],
      value: "",
      // orderAmount:0,//品类/多品优惠券    转加
      // moreAmount:0  //订单优惠券     转加


      couponList:[],//通用券 优惠卷列表
      specCouponList:[],//特殊(多品、品类)优惠卷列表
      noBuyCouponList:[],//未购优惠券列表


      couponItemList:[],//单品优惠券选中项，叠加或者不叠加数值
      couponFlag:false //优惠券点击开关
    };
  },
  //  computed: {
  //   ...mapState({
  //     goods: (state) => state.goods,
  //   }),
  //  },
  components: {
    TitleHeader,
    DeliveryInfo,
    CommodityList,
    DiscountFree,
    AmountBottom,
  },
  methods: {

    findItem(arr,id){
            const result = arr.findIndex(item => item === id);
            if(result === -1){
                arr.push(id)
            }else{
                arr.splice(result,1)
            }
            return arr
        },

        // 判断通用券有无选中状态，根据选中状态，改变通用券样式
        isCheckedTyFun(){
          let isCheckedTy=false;
                    this.couponList.forEach(item=>{
                      if(item.isChecked){
                        isCheckedTy=true;
                      }
                    })
                    if(isCheckedTy){
                        this.couponList.forEach(item=>{
                          if(item.isChecked){
                              this.couponList.forEach(item=>{
                                this.$set(item,'AllStatus',1)
                                this.$set(item,'isChecked',false)
                              })
                              this.$set(item,'AllStatus',1)
                              this.$set(item,'isChecked',true)
                          }
                        })
                    }else{
                        this.couponList.forEach(item=>{
                          this.$set(item,'AllStatus',1)
                          this.$set(item,'isChecked',false)
                        })
                    }
        },

        isCheckedSpecFun(){
          let isCheckedSpec=false;
              this.specCouponList.forEach(item=>{
                if(item.isChecked){
                  isCheckedSpec=true;
                }
              })
              if(isCheckedSpec){
                  //特殊(多品、品类)优惠卷列表
                  this.specCouponList.forEach(item=>{
                    if(item.isChecked){
                        this.specCouponList.forEach(item=>{
                          this.$set(item,'AllStatus',1)
                          this.$set(item,'isChecked',false)
                        })
                        this.$set(item,'AllStatus',1)
                        this.$set(item,'isChecked',true)
                    }
                  })
              }else{
                  this.specCouponList.forEach(item=>{
                    this.$set(item,'AllStatus',1)
                    this.$set(item,'isChecked',false)
                  })
              }
        },
    // 单个商品勾选优惠券 传值
    amountItemFun(data){
          let i=Number(data.soleId)
          this.orderList.orderDetails[i].couponId=data.couponId
          this.orderList.orderDetails[i].couponAmount=data.amount
          console.log('当前选中单品优惠券id',this.orderList.orderDetails[i].couponId==undefined) 
          console.log('当前选中单品优惠券可减金额',this.orderList.orderDetails[i].couponAmount)  
          let couponAmount=0;
          for(var m=0 ;m<this.orderList.orderDetails.length;m++){
            if(Number(this.orderList.orderDetails[m].couponAmount)>0){
              couponAmount+=Number(this.orderList.orderDetails[m].couponAmount)
            }
          }
          this.orderList.couponDetailAmount=couponAmount.toFixed(2)

          // alert(this.orderList.couponDetailAmount)
         this.payableAmountFun()

         let  couponItemArr=this.findItem(this.couponItemList,data.superimposeStatus)
         console.log(couponItemArr)
        //  console.log('this.orderListthis.orderListthis.orderList',this.couponList)
          if(this.orderList.orderDetails[i].couponId==undefined){
            if(this.orderList.couponDetailAmount>0){
                //如果单品券有一个选中状态，则多品/品类券则不可使用
                console.log('已取消当前这张单品券')
                console.log(this.couponItemList)
                console.log(data)
                  // 获取 当前有选中单品优惠券
                  console.log(couponItemArr.indexOf(0))
                  if(couponItemArr.indexOf(0)>-1){
                     //  如果当前有选中单品优惠券中有不可叠加 (0不可叠加 )优惠券
                    //  alert('有不可叠加则通用券不可用')
                    localStorage.setItem("AllStatus", 0);
                  }else{
                    // 反之则通用券可用
                    localStorage.setItem("AllStatus", 1);
                  }

                this.specCouponList.forEach(item=>{
                      this.$set(item,'isChecked',false)
                      this.$set(item,'AllStatus',0)
                })
            }else{
                localStorage.setItem("AllStatus", 1);
                this.couponList.forEach(item=>{
                  if(item.isChecked){
                    this.$set(item,'AllStatus',1)
                      this.$set(item,'isChecked',true)
                  }else{
                    this.$set(item,'AllStatus',1)
                      this.$set(item,'isChecked',false)
                  }
                })
                // 多品/品类可使用
                this.specCouponList.forEach(item=>{
                      this.$set(item,'isChecked',false)
                      this.$set(item,'AllStatus',1)
                })
            // 几类优惠券  全部可点击
            }
          }else{
            
            if(data.superimposeStatus==0){
             //不可叠加券
              //  alert('已选中不可叠加单品券')
              // 通用券优惠券  全部置灰 未选中
              localStorage.setItem("AllStatus", 0);
              this.couponList.forEach(item=>{
                    this.$set(item,'isChecked',false)
              })
            }else{
              // 可叠加
                  // alert('已选中可叠加单品券')
                  // 如果当前单品券选中，且所有选中单品种有不可叠加0，则通用券不可叠加，多品/品类券则不可选，反之通用券可选，多品/品类券则不可选
                  if(couponItemArr.indexOf(0)>-1){
                     //  如果当前有选中单品优惠券中有不可叠加 (0不可叠加 )优惠券
                    //  alert('选中单品有不可叠加，通用券不可用')
                    localStorage.setItem("AllStatus", 0);
                  }else{
                    // alert('选中单品都可叠加，通用券可用')
                    // 反之则通用券可用，判断通用券有选中项
                    // localStorage.setItem("AllStatus", 1);
                     this.isCheckedTyFun()

                  }
            }
            // 如果当前单品券选中，多品/品类券则不可选
             // 多品/品类可使用不可使用
             this.specCouponList.forEach(item=>{
                    this.$set(item,'isChecked',false)
                    this.$set(item,'AllStatus',0)
              })
            
            
          }
    },
//优惠券列表选择事件 CheckedFun(couponItem)
CheckedFun(couponItem){
      this.couponFlag=true
      if(couponItem.AllStatus==0){
        this.$message({
          message:'不能和当前已选优惠券叠加使用',
          type: "warning",
        });
        return false
      }
      //通用券 优惠卷列表   选择情况判断
      if(couponItem.couponType==0){
        //通用券 优惠卷列表
        // console.log('当前已选中')   true选中  false未选中
        couponItem.isChecked=!couponItem.isChecked;
        if(couponItem.isChecked){
           console.log('选中一个通用券 ')
          this.couponList.forEach(item=>{
            this.$set(item,'AllStatus',1)
            this.$set(item,'isChecked',false)
          })
          couponItem.AllStatus=1
          couponItem.isChecked=true
          // 如果通用券有选中状态,判断多品券是否为可叠加，如果为不可叠加，则让他不可选
          if(this.orderList.couponDetailAmount>0){
              //单品券有选中状态
              this.specCouponList.forEach(item=>{
                    this.$set(item,'isChecked',false)
                    this.$set(item,'AllStatus',0)
              })
          }else{
              // //特殊(多品、品类)优惠卷列表
              this.specCouponList.forEach(item=>{
                // // 品类/多品券  0不可叠加
                if(item.superimposeStatus==0){
                  this.$set(item,'AllStatus',0)
                  this.$set(item,'isChecked',false)
                }else{
                  if(item.isChecked){
                    this.$set(item,'AllStatus',1)
                      this.$set(item,'isChecked',true)
                  }else{
                    this.$set(item,'AllStatus',1)
                      this.$set(item,'isChecked',false)
                  }
                }
              })
          }
            
        }else{
          this.couponList.forEach(item=>{
            this.$set(item,'AllStatus',1)
            this.$set(item,'isChecked',false)
          })

          // 如果通用券有选中状态,判断多品券是否为可叠加，如果为不可叠加，则让他不可选
          if(this.orderList.couponDetailAmount>0){
              //单品券有选中状态
              this.specCouponList.forEach(item=>{
                    this.$set(item,'isChecked',false)
                    this.$set(item,'AllStatus',0)
              })
          }else{
              this.isCheckedSpecFun()
             
          }
        }
      }
       //多拼/品类券 优惠卷列表   选择情况判断
       if(couponItem.couponType==2 || couponItem.couponType==3){
          // console.log('当前已选中')   true选中  false未选中
          couponItem.isChecked=!couponItem.isChecked;
          if(couponItem.isChecked){
                 this.specCouponList.forEach(item=>{
                    this.$set(item,'AllStatus',0)
                    this.$set(item,'isChecked',false)
                  })
                  couponItem.AllStatus=1
                  couponItem.isChecked=true
              // //特殊(多品、品类)优惠卷列表
              // // 品类/多品券  0不可叠加
              if(couponItem.superimposeStatus==0){
                this.couponList.forEach(item=>{
                  this.$set(item,'AllStatus',0)
                  this.$set(item,'isChecked',false)
                })
              }else{
                this.isCheckedTyFun()
              }
          }else{
                  let isCheckedTy=false;
                  this.couponList.forEach(item=>{
                    if(item.isChecked){
                      isCheckedTy=true;
                    }
                  })
                  if(isCheckedTy){
                      this.couponList.forEach(item=>{
                        if(item.isChecked){
                            this.couponList.forEach(item=>{
                              this.$set(item,'AllStatus',0)
                              this.$set(item,'isChecked',false)
                            })
                            this.$set(item,'AllStatus',1)
                            this.$set(item,'isChecked',true)
                        }
                      })
                      // //特殊(多品、品类)优惠卷列表
                        this.specCouponList.forEach(item=>{
                          // // 品类/多品券  0不可叠加
                          if(item.superimposeStatus==0){
                            this.specCouponList.forEach(item=>{
                                this.$set(item,'AllStatus',1)
                                this.$set(item,'isChecked',false)
                            })
                            this.$set(item,'AllStatus',0)
                            this.$set(item,'isChecked',false)
                          }else{
                            this.specCouponList.forEach(item=>{
                              this.$set(item,'AllStatus',1)
                                this.$set(item,'isChecked',false)
                            })
                          }
                        })
                  }else{
                      this.couponList.forEach(item=>{
                        this.$set(item,'AllStatus',1)
                        this.$set(item,'isChecked',false)
                      })

                      this.specCouponList.forEach(item=>{
                              this.$set(item,'AllStatus',1)
                                this.$set(item,'isChecked',false)
                            })
                      
                  }
            
          }
       }
      // 未购券   优惠券列表  
      if(couponItem.couponType==4){
        // console.log('当前已选中')   true选中  false未选中
        couponItem.isChecked=!couponItem.isChecked;
        if(couponItem.isChecked){
          this.noBuyCouponList.forEach(item=>{
            this.$set(item,'isChecked',false)
          })
          couponItem.isChecked=true
        }else{
          this.noBuyCouponList.forEach(item=>{
              this.$set(item,'isChecked',false)
            })
        }
      }
      


 
     
       //通用券 优惠卷列表  
       let  couponAmountNum=0; 
      this.couponList.forEach(item=>{
        if(item.isChecked){
          couponAmountNum+=item.exemptionAmount

          // couponAmount
        }
      })
      this.orderList.couponAmount=Number(couponAmountNum)

      // 通用券金额
      localStorage.setItem('couponAmountNum',this.orderList.couponAmount)


if(this.orderList.couponAmount==0){
  this.orderList.couponId=''
}


      // this.$set('orderList','couponAmount',couponAmountNum)
      //特殊(多品、品类)优惠卷列表
      let specCouponAmount=0
      this.specCouponList.forEach(item=>{
        if(item.isChecked){
          specCouponAmount+=item.exemptionAmount
        }
      })
      this.orderList.specCouponAmount=Number(specCouponAmount)

      // 特殊(多品、品类)优惠卷金额
      localStorage.setItem('specCouponAmount',this.orderList.specCouponAmount)


      //未购优惠券列表
      let noBuyCouponAmount=0
      this.noBuyCouponList.forEach(item=>{
        if(item.isChecked){
          console.log('itemitemitemitem',item)
          noBuyCouponAmount+=item.exemptionAmount
        }
      })
      this.orderList.noBuyCouponAmount=Number(noBuyCouponAmount)


      console.log(' this.orderList', this.orderList)
       // 调取函数，传递值
       this.payableAmountFun()

},
    //订单优惠券勾选  传值
    amountOrderFun(data){
      this.couponFlag=true
      if(data==''){
          this.orderList.couponAmount=0
          this.orderList.couponId=''
      }else{
       
          this.orderList.couponAmount=data.amount
          this.orderList.couponId=data.couponId
      }
       this.payableAmountFun()
    },

    payableAmountFun(){
       let  couponAmountNum=0;    //通用优惠券
      if(this.orderList.couponAmount>0){
          couponAmountNum=Number(this.orderList.couponAmount)
      }
      let originalAmount=Number(this.orderList.originalAmount)    //商品金额

      let couponDetailAmount=Number(this.orderList.couponDetailAmount) //单品优惠券
      let promotionAmount=Number(this.orderList.promotionAmount)   //促销折扣

      let specCouponAmountNum=Number(this.orderList.specCouponAmount)   //品类/多品券
      // alert(specCouponAmountNum)
      let noBuyCouponAmountNum=Number(this.orderList.noBuyCouponAmount)   //未购券

      // 除运费之后的价格
      let  totalPrice=originalAmount-couponDetailAmount-promotionAmount-couponAmountNum-noBuyCouponAmountNum-specCouponAmountNum
      if(totalPrice<0){
        totalPrice= Number(this.orderList.goodsCount) * 0.01
      }

      let freight=Number(this.orderList.freight)     //运费
      // alert(this.orderList.orderType!=4 && this.orderList.orderType!=5)
      if(totalPrice>0){
        if(this.orderList.orderType==3){
          // 预售单独成单，计算
            let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum-noBuyCouponAmountNum-specCouponAmountNum
            this.orderList.payableAmount=payableAmount.toFixed(2);
            this.orderList.freight=Number(freight) 
        }else{
          if(this.couponFlag){
              // 如果非a+b活动订单  请求接口查运费区间，根据运费区间得到运费价格
              if(this.orderList.orderType!=4 && this.orderList.orderType!=5){
                freightList().then(res=>{
                    let records=res.data.data.records;
                    records.forEach(item => {
                      if(totalPrice>item.startMoney && totalPrice<item.endMoney){
                          freight=Number(item.freight)     //运费
                          // 应付金额
                          let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum-noBuyCouponAmountNum-specCouponAmountNum
                          this.orderList.payableAmount=payableAmount.toFixed(2);
                          this.orderList.freight=Number(item.freight)
                          //  alert('aaaaa'+ this.orderList.freight)
                          return false
                      }
                    });
                  })
              }else{
                let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum-noBuyCouponAmountNum-specCouponAmountNum
                this.orderList.payableAmount=payableAmount.toFixed(2);
                this.orderList.freight=Number(freight) 
              }
          }else{
            let  payableAmount=originalAmount+freight-couponDetailAmount-promotionAmount-couponAmountNum-noBuyCouponAmountNum-specCouponAmountNum
            this.orderList.payableAmount=payableAmount.toFixed(2);
            this.orderList.freight=Number(freight) 
          }
        }

      }
      // let freight=Number(this.orderList.freight)     //运费
    },
    // 通过data渲染数据
    RenderData(data){
          this.orderList = data.data.data;
          this.isReady=true;
          // this.orderAmount=this.orderList.couponAmount                //品类/多品优惠券    转加
          // this.moreAmount=this.orderList.specCouponAmount             //订单优惠券     转加
          //发票信息
          switch (this.orderList.memberStore.invoiceType) {
            case 1:
              this.invoiceType = "纸质发票";
              break;
            case 2:
              this.invoiceType = "电子发票";
              break;

            default:
              this.invoiceType = "无";
              break;
          }
          switch (this.orderList.memberStore.invoiceManner) {
            case 1:
              this.invoiceManner = "普票";
              break;
            case 2:
              this.invoiceManner = "增值税专用发票";
              break;
            default:
              this.invoiceManner = "无";
              break;
          }
          this.couponList=this.orderList.couponList    //通用券 优惠卷列表
          this.specCouponList=this.orderList.specCouponList//特殊(多品、品类)优惠卷列表
          this.noBuyCouponList=this.orderList.noBuyCouponList//未购优惠券列表
          // AllStatus:false, // 初始为false。优惠券都可选，反之则都不可选
                // alert('false:其余都置灰  #'+localStorage.getItem("AllStatus"))

            if(this.orderList.couponDetailAmount>0){
                          //如果单品券初始进来有选中状态
                        // 多品/品类券则不可使用
                          this.specCouponList.forEach(item=>{
                                this.$set(item,'isChecked',false)
                                this.$set(item,'AllStatus',0)
                          })
            }else{
              // 单品无选中状态
              // 通用券初始进来有选中状态，则其他通用券置灰，
              this.couponList.forEach(item=>{
                if(item.isChecked){
                    this.couponList.forEach(item=>{
                      this.$set(item,'AllStatus',0)
                      this.$set(item,'isChecked',false)
                    })
                    item.AllStatus=1
                    item.isChecked=true
                }
              })
              // 多品/品类券初始进来有选中状态，则其他多品/品类券置灰，
              if( this.specCouponList.length>0){
                this.specCouponList.forEach(item=>{
                  if(item.isChecked){
                      this.specCouponList.forEach(item=>{
                        this.$set(item,'AllStatus',1)
                        this.$set(item,'isChecked',false)
                      })
                      item.AllStatus=1
                      item.isChecked=true
                  }
                })
              }
            }
            // //单品优惠券选中项，叠加或者不叠加数值
            this.orderList.orderDetails.forEach(item=>{
              if(item.couponList.length>0){
                item.couponList.forEach(i=>{
                  if(i.isChecked){
                    this.couponItemList.push(i.superimposeStatus)
                  }
                })
              }
            })
            localStorage.setItem('specCouponAmount',Number(this.orderList.specCouponAmount))        //初始多品券

            
             localStorage.setItem('couponAmountNum',Number(this.orderList.couponAmount))   // 初始通用券金额
                console.log('通用券 优惠卷列表',this.couponList)
                console.log('特殊(多品、品类)优惠卷列表',this.specCouponList)
                console.log('未购优惠券列表',this.noBuyCouponList)

                this.payableAmountFun()
          // 关闭loading
          this.$loading().close();
    }


  },
  mounted() {
    //解决this指向问题，在window.addEventListener中this是指向window的。
    //需要将vue实例赋值给_this,这样在window.addEventListener中通过_this可以为vue实例上data中的变量赋值
    let _this=this;
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent",function(e){
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if(e.key==="AllStatus"){
        // console.log('aaaaaaaaaaaa',e.newValue);
        _this.couponList.forEach(item=>{
          _this.$set(item,'AllStatus',e.newValue)
        })

        // 多品，品类券
        // _this.specCouponList.forEach(item=>{
        //     _this.$set(item,'AllStatus',e.newValue)
        // })
        // _this.noBuyCouponList.forEach(item=>{
        //   _this.$set(item,'AllStatus',e.newValue)
        // })
      }
    })

  },
  created() {
    
     // 显示loading
      this.$loading({ fullscreen: true ,background:'#ffffff45'})
      if(this.$route.query.id=="combo"){

        // 限时购
        let datas=JSON.parse(sessionStorage.getItem("comBo"))
            console.log(datas)
        PromotionOrder(datas).then(data=>{
          if(data.data.code==400){
                this.$message({
                  message: data.data.msg,
                  type: "warning",
                });
               window.history.back()
            }
           // 通过data渲染数据
            this.RenderData(data)
          // aaaaaaaaaa--------------zhushi-----------------
          //  let CarData=data
          //  // 获取购物车加入订单  优惠券  start
          //  let CouponMoreIds = "";
          //  let items=data.data.data.orderDetails;
          //   for (var i = 0; i < items.length; i++) {
          //     let CouponId = items[i].goodsId;
          //     CouponMoreIds += CouponId + ",";
          //   }
          //      // 获取购物车加入订单  优惠券
          //   relativeCoupon(CouponMoreIds).then((data) => {
              
          //     if(data.data.data.length===0){
          //           // 通过data渲染数据
          //         this.RenderData(CarData)
          //     }else{
          //         carToOrder(ids).then((data) => {
          //           // 通过data渲染数据
          //           this.RenderData(data)
          //         })
          //     }
          //   });
            // 获取购物车加入订单  优惠券  end

            // aaaaaaaaaa--------------zhushi-----------------
        })
        return false
      }
      else if(this.$route.query.id=="AdvanceSale"){
            // 预售
            let datas=JSON.parse(sessionStorage.getItem("AdvanceSale"))
            console.log('预售datas',datas)
            PromotionOrder(datas).then(data=>{
                if(data.data.code==400){
                    this.$message({
                      message: data.data.msg,
                      type: "warning",
                    });
                  window.history.back()
                }
              // 通过data渲染数据
                this.RenderData(data)
            })
            return false
      }
       else if(this.$route.query.id=="FlashGroup"){

        // 限时拼团
        let datas=JSON.parse(sessionStorage.getItem("FlashGroup"))
            console.log(datas)
        PromotionOrder(datas).then(data=>{
          if(data.data.code==400){
                this.$message({
                  message: data.data.msg,
                  type: "warning",
                });
               window.history.back()
            }
           // 通过data渲染数据
            this.RenderData(data)
        })
        return false
      }
    // if(this.$route.query.id){
    //   // 从再来一单进入订单结算
    //   let orderIds={
    //     id:this.$route.query.id
    //   }
    //   AgainOrder(orderIds).then((data)=>{
    //      if(data.data.code==400){
    //             this.$message({
    //               message: data.data.msg,
    //               type: "warning",
    //             });
    //            this.$router.push({ path: "/cart" });
    //       }
    //     // 通过data渲染数据
    //      this.RenderData(data)
    //     // 获取购物车加入订单  优惠券  start
    //     let CouponMoreIds = "";
    //     let items=data.data.data.orderDetails;
    //     for (var i = 0; i < items.length; i++) {
    //       let CouponId = items[i].goodsId;
    //       CouponMoreIds += CouponId + ",";
    //     }
    //     // 获取购物车加入订单  优惠券
    //     relativeCoupon(CouponMoreIds).then(() => {
    //     });
    //      // 获取购物车加入订单  优惠券  end
    //   })
    // }else{
        let moreIds=this.$route.query.ids
        let cancelGift=this.$route.query.cancelGift
        let notBuyNum=this.$route.query.notBuyCount

        let allowCoupon=this.$route.query.allowCoupon

        let ids={}
        //获取所有商品的ids
        this.moreIds = moreIds;
        if(allowCoupon==0){
           ids = { ids: this.moreIds ,cancelGift:cancelGift,notBuyNum:notBuyNum,allowCoupon:allowCoupon};
        }else{
           ids = { ids: this.moreIds ,cancelGift:cancelGift,notBuyNum:notBuyNum};
        }
        // 获取购物车加入订单接口
        carToOrder(ids).then((data) => {
              if(data.data.code==400){
                    this.$message({
                      message: data.data.msg,
                      type: "warning",
                    });
                    window.history.back()
                  //  this.$router.push({ path: "/cart" });
              }else{
                    // 通过data渲染数据
                    this.RenderData(data)
              }
           // aaaaaaaaaa--------------zhushi-----------------
          // let CarData=data
          //  // 获取购物车加入订单  优惠券  start
          //  let CouponMoreIds = "";
          //  let items=data.data.data.orderDetails;
          //   for (var i = 0; i < items.length; i++) {
          //     let CouponId = items[i].goodsId;
          //     CouponMoreIds += CouponId + ",";
          //   }
          //      // 获取购物车加入订单  优惠券
          //   relativeCoupon(CouponMoreIds).then((data) => {
          //     if(data.data.data.length===0){
          //           // 通过data渲染数据
          //         this.RenderData(CarData)
          //     }else{
          //         carToOrder(ids).then((data) => {
          //           // 通过data渲染数据
          //           this.RenderData(data)
          //         })
          //     }
               
          //   });
            // 获取购物车加入订单  优惠券  end
             // aaaaaaaaaa--------------zhushi-----------------
        });
    // }
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.titBox{
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}
.notitBox{
   padding: 20px;
}
.titmsg {
  font-size: 16px;
  color: #333;
  line-height: 62px;
  font-weight: bold;
}
.invoiceItem {
  padding: 0 20px 20px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  p {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    
  }
}
.paymentType {
  padding: 0 20px 36px 20px;
  border: 1px solid #f0f0f0;
  border-top: none;
  .paymentLine {
    font-size: 14px;
    color: #666666;
    line-height: 30px;
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .TitLabel {
      padding-right: 10px;
    }
    .payBox{
        padding:6px 18px;
        width:66px;
        height:20px;
        display: inline-block;
         text-align: center;
        line-height:20px;
        border: 2px solid #ff3333;
        background: url("../assets/checkedRed.png") no-repeat right bottom;
        background-size: 26px 26px;
    }
  }
}

.CouponListBigBox{
  border: 2px dashed #E6E6E6;
}
// 优惠券列表样式
.CouponListBox{
  padding:0 20px 20px 23px;
  .clear{
    clear: both;
  }
  .CouponBox{
    width: 100%;
    height: auto;
    .TitP{
      color: #F43C38 ;
      font-size: 16px;
      line-height:30px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    ul{
      width: 100%;
      li{
     
        width: 25%;
        float: left;
        margin-bottom: 10px;
        .couponItemBox{
             width:calc( 100% - 20px ) ;
             margin-left: 10px;
             position: relative;
             padding-top:10px;
            &.disable{
              border:2px solid #d1d1d1;
               border-top: none;
               position: relative;
               .checkedBox{
                 position: absolute;
                 right: 0;
                 bottom: 0;
                 background: url('../assets/checkedRed.png') no-repeat;
                 background-size: 28px 30px;
                 width:28px;
                 height:30px;
               }
              .Hearbox{
                  top:0px;
                  left:0px;
                  position: absolute;
                  height:10px;
                  width: 100%;
                  background: url('../assets/disable.png') repeat;
                  background-size: 19px 10px
                }

              .mainBox{
                padding: 10px;
                background:#d1d1d1;
                .mainTop{
                  width: 100%;
                  line-height: 42px;
                  height: 42px;
                  .numWraper{
                    width: 40%;
                    float: left;
                    text-align: center;
                    .num{
                      color: #fff;
                      font-size:24px;
                      font-weight: bold;
                    }
                   
                  }
                  .numMsg{
                    width: 60%;
                    float: left;
                    text-align: left;
                    color: #fff;
                      font-size: 16px;
                  }
                }
                .date{
                  width: 100%;
                  color: #999999 ;
                  font-size: 14px;
                  text-align: center;
                }
              }
              p{
                padding:0 15px;
                color: #999999 ;
                font-size:13px;
                line-height: 30px;
              }
            }

             &.nomal{
              border:2px solid #FDCF80;
               border-top: none;
               position: relative;
               .checkedBox{
                 position: absolute;
                 right: 0;
                 bottom: 0;
                 background: url('../assets/checkedRed.png') no-repeat;
                 background-size: 28px 30px;
                 width:28px;
                 height:30px;
               }
              .Hearbox{
                  top:0px;
                  left:0px;
                  position: absolute;
                  height:10px;
                  width: 100%;
                  background: url('../assets/Radian.png') repeat;
                  background-size: 19px 10px
                }

                .mainBox{
                  padding: 10px;
                  background:#FDCF80;
                  .mainTop{
                    width: 100%;
                    line-height: 42px;
                    height: 42px;
                    .numWraper{
                      width: 40%;
                      float: left;
                      text-align: center;
                      .num{
                        color: #8C4318;
                        font-size:24px;
                        font-weight: bold;
                      }
                    
                    }
                    .numMsg{
                      width: 60%;
                      float: left;
                      text-align: left;
                      color: #782F03;
                        font-size: 16px;
                    }
                  }
                  .date{
                    width: 100%;
                    color: #782F03;
                    font-size: 14px;
                    text-align: center;
                  }
                }
                p{
                  padding:0 15px;
                  color: #8C4318;
                  font-size:13px;
                  line-height: 30px;
                }
             }
            
             &.active{
               border:2px solid #F43C38;
               border-top: none;
               position: relative;
               .checkedBox{
                 position: absolute;
                 right: 0;
                 bottom: 0;
                 background: url('../assets/checkedRed.png') no-repeat;
                 background-size: 28px 30px;
                 width:28px;
                 height:30px;
               }
                .Hearbox{
                  top:0px;
                  left:0px;
                  width: 100%;
                  height:10px;
                  position: absolute;
                  background: url('../assets/RadianActive.png') repeat;
                  background-size: 19px 10px
                }

                .mainBox{
                    padding: 10px;
                    background:#FDCF80;
                    .mainTop{
                      width: 100%;
                      line-height: 42px;
                      height: 42px;
                      .numWraper{
                        width: 40%;
                        float: left;
                        text-align: center;
                        .num{
                          color: #8C4318;
                          font-size:24px;
                          font-weight: bold;
                        }
                      
                      }
                      .numMsg{
                        width: 60%;
                        float: left;
                        text-align: left;
                        color: #782F03;
                          font-size: 16px;
                      }
                    }
                    .date{
                      width: 100%;
                      color: #782F03;
                      font-size: 14px;
                      text-align: center;
                    }
                  }
                  p{
                    padding:0 15px;
                    color: #8C4318;
                    font-size:13px;
                    line-height: 30px;
                  }
              
             }

            
             
        }
      }
    }
  }
 
}


// 备注 样式
.complimentary {
      height: 120px;
      // border: 1px solid #f0f0f0;
      .remarkBox {
        padding: 20px;
        // border-right: 1px solid #f0f0f0;
        height: 120px;
        .leftRemark {
          font-size: 12px;
          color: #666;
          font-weight: bold;
          width: 40px;
          float: left;
        }
        .rightRemark {
          font-size: 12px;
          color: #999;
          width: calc(100% - 40px);
          background: #fff;
          height: 80px;
          float: left;
          /deep/.el-textarea {
            width: 100%;
            height: 100%;
            .el-textarea__inner {
              width: 100%;
              height: 100%;
              border: 1px solid #f0f0f0;
              padding: 10px;
            }
          }
        }
      }
      // .specialOfferBox {
      //   height:60px;
      //   .topSpecialOffer {
      //     height:40px;
      //     padding: 20px 20px  0;
      //     .desTit{
      //         float: left;
      //         width: 100px;
      //         text-align: right;
      //         margin-right: 25px;
      //         line-height:40px;
      //         font-size: 12px;
      //         color: #666;
      //     }
      //     .desCenter{
      //           float: left;
      //         width: calc(100% - 225px);
      //         font-size: 12px;
      //         color: #666;
      //         line-height:40px;
      //         /deep/.el-select {
      //             height: 30px;
      //             width: 175px;
      //             line-height: 30px;
      //             .el-input__inner {
      //             height: 30px;
      //             }
      //             .el-input__icon {
      //             line-height: 30px;
      //             }
      //         }
      //     }
      //     .desEnd{
      //         float: left;
      //         width:100px;
      //         text-align: right;
      //         line-height:40px;
      //         font-size: 14px;
      //         color: #333;
      //     }
      //   }
      //   .bottomSpecialOffer {
      //     height:50px;
      //     padding: 0 20px;
      //     .desTit{
      //         float: left;
      //         width: 100px;
      //         text-align: right;
      //         margin-right: 25px;
      //         line-height:50px;
      //         font-size: 12px;
      //         color: #666;
      //     }
      //     .desCenter{
      //           float: left;
      //         width: calc(100% - 225px);
      //         font-size: 12px;
      //         color: #666;
      //         line-height:50px;
      //         /deep/.el-select {
      //             height: 30px;
      //             width: 175px;
      //             line-height: 30px;
      //             .el-input__inner {
      //             height: 30px;
      //             }
      //             .el-input__icon {
      //             line-height: 30px;
      //             }
      //         }
      //     }
      //     .desEnd{
      //         float: left;
      //         width:100px;
      //         text-align: right;
      //         line-height:50px;
      //         font-size: 14px;
      //         color: #333;
      //     }
      //   }
      // }
    }
</style>
